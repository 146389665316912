<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'purchasing.pending-requests' }">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.pending }}</h2>
                  <p class="fontsize-sm m-0 text-warning">
                    Pending
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-warning">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'purchasing.vehicle-request-histories', params: {purchased_by_status: 'Approved'}}">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.approved }}</h2>
                  <p class="fontsize-sm m-0 text-sucess">
                    Approved
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon dark-icon-light iq-bg-success">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <router-link :to="{ name: 'purchasing.vehicle-request-histories', params: {purchased_by_status: 'Disapproved'}}">
          <iq-card>
            <template v-slot:body>
              <div class="d-flex d-flex align-items-center justify-content-between">
                <div>
                  <h2>{{ requests.disapproved }}</h2>
                  <p class="fontsize-sm m-0 text-danger">
                    Disapproved
                  </p>
                </div>
                <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-danger">
                  <i class="ri-inbox-fill" /></div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { PurchasingDashboardService } from '@/services'

export default {
  name: 'PurchasingDashboard',

  middleware: ['auth', 'purchasing'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      requests: {
        pending: 0,
        approved: 0,
        disapproved: 0
      }
    }
  },

  mounted () {
    core.index()
    this.fetchStatistics()
  },

  methods: {
    async fetchStatistics () {
      await PurchasingDashboardService.getStatistics().then(({ data }) => {
        this.requests = data
      })
    }
  }
}
</script>
